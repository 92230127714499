import React from 'react';
import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';

import styles from './ourculture.module.css';


const PeopleCulture = () => {

    return (
        <Grid className={styles.culture_container} 
            sx={{paddingTop: "0px"}}>
            <Grid className={styles.culture_subcontainer} >
                <Grid sx={{display:'flex', flexWrap:'wrap', columnGap:"7rem"}}>
                    <Grid sx={{width:{xs:"100%", sm:"50%",  maxWidth:"400px", borderTop:"3px solid #B0D22D", paddingTop:"1rem"}}}>
                        <Typography variant="h2" component={"h2"} className={styles.culture_sub_title} sx={{marginBottom:"1.5rem"}}>
                            Collaboration
                        </Typography>
                        <Typography variant="body2" component={"p"} className={`${styles.culture_content} ${styles.colorGray}`} sx={{width:"80%",marginBottom:"1.5rem"}}>
                            Collaboration is at the heart of our success. We firmly believe teamwork and open communication enable us to achieve shared goals and deliver exceptional results.
                        </Typography>
                    </Grid>
                    <Grid sx={{width:{xs:"100%", sm:"50%",  maxWidth:"400px", borderTop:"3px solid #B0D22D", paddingTop:"1rem"}}}>
                        <Typography variant="h2" component={"h2"} className={styles.culture_sub_title} sx={{marginBottom:"1.5rem"}}>
                        Integrity
                        </Typography>
                        <Typography variant="body2" component={"p"} className={`${styles.culture_content} ${styles.colorGray}`} sx={{width:"80%",marginBottom:"1.5rem"}}>
                        Integrity is non-negotiable for us. We always uphold the highest ethical standards in all projects, ensuring transparency, honesty, and trust, both internally and with our clients.
                        </Typography>
                    </Grid>
                    <Grid sx={{width:{xs:"100%", sm:"50%",  maxWidth:"400px", borderTop:"3px solid #B0D22D", paddingTop:"1rem"}}}>
                        <Typography variant="h2" component={"h2"} className={styles.culture_sub_title} sx={{marginBottom:"1.5rem"}}>
                            Innovation
                        </Typography>
                        <Typography variant="body2" component={"p"} className={`${styles.culture_content} ${styles.colorGray}`} sx={{width:"80%",marginBottom:"1.5rem"}}>
                            We continuously push the boundaries of what's possible. Adoption of new technologies and a creative mindset empowers our teams to develop futuristic solutions to meet clients' evolving needs.
                        </Typography>
                    </Grid>
                    <Grid sx={{width:{xs:"100%", sm:"50%",  maxWidth:"400px", borderTop:"3px solid #B0D22D", paddingTop:"1rem"}}}>
                        <Typography variant="h2" component={"h2"} className={styles.culture_sub_title} sx={{marginBottom:"1.5rem"}}>
                            Accountability
                        </Typography>
                        <Typography variant="body2" component={"p"} className={`${styles.culture_content} ${styles.colorGray}`} sx={{width:"80%",marginBottom:"1.5rem"}}>
                            We take full responsibility for our actions and decisions, be it our clients, partners, or teams. Commitment to accountability empowers us to consistently exceed expectations.
                        </Typography>
                    </Grid>
                    <Grid sx={{width:{xs:"100%", sm:"50%",  maxWidth:"400px", borderTop:"3px solid #B0D22D", paddingTop:"1rem"}}}>
                        <Typography variant="h2" component={"h2"} className={styles.culture_sub_title} sx={{marginBottom:"1.5rem"}}>
                            Sustainability
                        </Typography>
                        <Typography variant="body2" component={"p"} className={`${styles.culture_content} ${styles.colorGray}`} sx={{width:"80%",marginBottom:"1.5rem"}}>
                        Sustainability is a core to who we are. We implement eco-friendly practices and sustainable technologies in our projects, ensuring we contribute positively to the environment and future generations.
                        </Typography>
                    </Grid>
                </Grid>
                
            </Grid>
        </Grid>
    )
}

export default PeopleCulture;