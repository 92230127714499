import React from 'react';
import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';
import styles from './advantagec.module.css'

const AdvantageC = () => {

    return (
        <Grid className={styles.advantagecontainer}>
            <Grid className={styles.advantage_subcontainer}>
                <div className={styles.advantage_container}>
                    <div className={styles.advantage_line}></div>
                    <div className={styles.advantage_circle_parent}>
                        <div className={styles.advantage_circle}> </div>
                    </div>
                </div>
                <Grid className={styles.advantage_content_container}>
                    <Typography variant='body2' component={"p"} className={styles.advantage_text} >Simon India <br/>
                        <span style={{textWrapMode:"nowrap",fontSize:"0.8rem", color:"#5a5a5a"}}>an Adventz company</span></Typography>
                    <img src="/assets/images/Adventz_new.png" alt="" />
                </Grid>
            </Grid>
        </Grid>
    )


}

export default AdvantageC;