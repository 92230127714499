import React, {useEffect} from 'react';
import Grid from '@mui/material/Grid2'

import PageBanner from '../../components/pageBanner';
import PageContent from '../../components/pageContent';
import AdvantageC from '../../components/advantageC';
import CorporateTeam from './corporateTeam';
import Board from './board';
import { isMobile } from 'react-device-detect';



const LeadershipTeam = () => {

    const bannerPath = [
        {title: 'Home', url: '/'},
        {title: 'Know us', url: '/'},
        {title: 'Leadership team', url: ''}
    ]
    useEffect(()=>{
            window.scrollTo({top:0, left:0})
    },[])

    return (
        <Grid sx={{display: 'flex', flexFlow:'column', width: '100%'}}>
            <PageBanner title= {isMobile ? "Leadership Team" : "Leadership<br/>Team"} bannerimage="leadership_banner.jpg" bannerPath={bannerPath} />
            <PageContent details="The leadership team at Simon India is the driving force behind our success and innovation. Comprised of seasoned professionals from diverse backgrounds, our Board of Directors and management team steer the company with vision, integrity and an unwavering commitment to excellence. Their expertise in engineering, project management and global strategy empowers Simon India to remain at the forefront of the industry.
<br /><br/>
Meet the minds shaping our future and driving sustainable growth across all operations."  />
            <Board />
            <CorporateTeam />
            <AdvantageC />
        </Grid>
    )

}

export default LeadershipTeam;

