import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid2'
import HomeBanner from '../../components/homebanner';
import HomePartners from '../../components/HomePartners';
import HomePossible from '../../components/HomePossible';
import HomeExpertise from '../../components/expertise';
import HomeProjects from '../../components/homeProjects';
import Clients from '../../components/clients';
import HomeClients from '../../components/homeClients'
import HomeGrow from '../../components/homeGrow';
import HomeResponsibility from '../../components/homeResponsibility';
import HomeNews from '../../components/homeNews';
import AdvantageC from '../../components/advantageC';
import Styles from './home.module.css';
import { Typography } from '@mui/material';
import { isMobile } from 'react-device-detect'


const Home = () => {

    useEffect(()=>{
            window.scrollTo({top:0, left:0})
    },[])
    return (
        <div>
            <div className= {Styles.banner_container}>
                <HomeBanner />
            </div>
            <Grid sx={{width:'100%', display:'flex', justifyContent:'center'}}>
                <Grid sx={{zIndex:1, maxWidth:'1080px', width:'100%', marginTop: !isMobile ? '-2.75rem' : "-2.60rem", marginRight: !isMobile ? "-10px": "18px" , display:'flex', justifyContent:'flex-end'}}> 
                    <a href="https://www.adventz.com/" target="_blank" rel="noreferrer">
                        <img src="/assets/images/banner-client.png" style={{width: !isMobile ? 'fit-content' : '122px'}} alt="adventz group"/>
                    </a>
                </Grid>
            </Grid>
            <HomePartners />
            <HomePossible />
            <HomeClients />
            <HomeExpertise />
            <HomeProjects isHome={true} />
            <HomeGrow />
            <Clients />
            <Grid className={Styles.holicontainer}>
                <Grid className={Styles.holisubcontainer}>
                    <Grid>
                        <Typography variant="h2" component={"h2"} className={Styles.holititle}>Engineering </Typography>
                        <Typography variant="h2" component={"h2"} className={`${Styles.holititle} ${Styles.holiblue}` }>Holistic Growth </Typography>
                    </Grid>
                    <img src="/assets/images/holiplant.png" alt="hli plant" />
                </Grid>
            </Grid>
            <HomeResponsibility />
            <HomeNews />
            <AdvantageC />

        </div>
    )

}

export default Home;