import React from 'react';
import Grid from '@mui/material/Grid2';
import styles from './pageBanner.module.css'
import { Breadcrumbs, Typography, Link } from '@mui/material';


const PageBanner = (props) => {
    const {title, bannerimage, bannerPath} = props;
    
    
    return (
        <Grid className={styles.pageContainer}>
            <Grid className={styles.pageSubContainer}>
                <Grid>
                    <Typography variant='h1' component={"h1"} className={styles.bannerTitle} 
                        dangerouslySetInnerHTML={{__html:title}} />
                    <Breadcrumbs aria-label="breadcrumb" sx={{marginTop:'0.3rem'}}>
                        {bannerPath.map((item, index) =>{
                            if(index >= bannerPath.length-1){
                              return  <Typography className={`${styles.breadcrumb_content} ${styles.breadcrumb_active}`}>{item.title}</Typography>
                            } else {
                               return <Link underline="active" className={styles.breadcrumb_content} href={`${item.url}`}>
                                    {item.title}
                                </Link>
                            }
                        })}
                        
                        
                    </Breadcrumbs>
                </Grid>
                <Grid >
                    <img src= {`/assets/images/${bannerimage}`} alt="" style={{borderRadius:5}} />
                </Grid>
            </Grid>
        </Grid>
    )

}

export default PageBanner