import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import PageBanner from '../../components/pageBanner';
import ValuePrepo from './valuePrepos';
import Clients from '../../components/clients';
import CorporateValue from './corporateValue';
import FaqContainer from './faqContainer';
import AdvantageC from '../../components/advantageC';
import { isMobile } from 'react-device-detect';
// import { Typography } from '@mui/material';


import PageContent from '../../components/pageContent';
import ValueExplore from './valueExpore';

const ValuePreposition = () => {

    const bannerPath = [
        {title: 'Home', url: '/'},
        {title: 'Know us', url: '/'},
        {title: 'Value proposition', url: ''}
    ]
    useEffect(()=>{
        window.scrollTo({top:0, left:0})
    },[])

    return (
        <Grid sx={{width:'100%'}}>
            <PageBanner title= {!isMobile ? "Value<br/>Proposition" : "Value Proposition"}  bannerimage="valyepreposition_banner.jpg" 
                bannerPath={bannerPath}  />
            <PageContent 
                details="At Simon India, we leverage latest digital tools and critical thinking to deliver innovative engineering solutions. Our commitment to speed and collaboration enhances efficiency at every stage of the project lifecycle, ensuring successful outcomes for our clients. With global work practices and a deep experiential legacy, we set the industry benchmark. Our team adapts to diverse markets while ensuring compliance with international standards, driving success through a collaborative and client-focused approach, making us a trusted partner in the industry." />
            <ValuePrepo />
            <ValueExplore />
            <CorporateValue />
            <Clients containerColor="#FFF" marginT="0px" />
            <FaqContainer />
            <AdvantageC />
        </Grid>
    )

}

export default ValuePreposition