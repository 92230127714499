import React from 'react';

import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';

import styles from './pagecontent.module.css';

const PageContent = (props) => {
    const {details} = props;
    return (
        <Grid className={styles.page_container}>
            <Grid className={styles.page_subcontainer}>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{__html:details}}
                />
            </Grid>
        </Grid>
    )
}

export default PageContent;