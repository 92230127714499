import React from 'react';
import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';


import styles from './sustainbility.module.css'
import { isMobile } from 'react-device-detect';

const VisionSus = () => {

    return (
        <>
        <Grid className={`${styles.sustainability_container}`}>
            <Grid className={`${styles.sustainability_subcontainer} ${styles.visionsus_container}`} >
                <img src="/assets/images/future_img.png" alt="" />
                <Typography variant="h2" component={"h2"} 
                    className={`${styles.sustainability_title} ${styles.colorGreen}`}
                    sx={{marginBottom:"4rem", paddingLeft: isMobile ? "0px": "2rem", paddingRight: isMobile ? "18px": "0px"}}>
                        Engineering a Sustainable Future: <br/>
                        Innovating Today for a Greener Tomorrow
                </Typography>
            </Grid> 
        </Grid>
        <Grid className={`${styles.sustainability_container} ${styles.visionsus_bg}`} sx={{marginBottom:"60px"}}>
            <Grid className={styles.sustainability_subcontainer} 
                sx={{paddingTop:"7rem", paddingBottom:"9rem"}} >
                    <Grid sx={{display:'flex', alignItems:'flex-end'}}>
                        <Typography variant="h3" component={"h3"} 
                            className={`${styles.sustainability_title} ${styles.colorWhite}`}
                            sx={{width: isMobile? "55%" :"33%", paddingLeft: isMobile ? "0px": "0px", paddingRight: isMobile ? "0px": "0px"}}>
                                Vision for a Sustainable Future
                        </Typography>
                        <img src={!isMobile ? "/assets/images/vision_img.png" : "/assets/images/vision_img_mobile.png"} alt="" style={{marginLeft: isMobile ? "-5rem": "-7.5rem"}}/>
                </Grid>
            </Grid>
        </Grid>
        <Grid className={`${styles.sustainability_container}`} sx={{marginBottom:"120px"}}>
            <Grid className={`${styles.sustainability_subcontainer} ${styles.vision_expert_subcontainer}`} >
                <Grid sx={{width:{xs:"100%", sm:"50%"}}}>
                    <Typography variant='body2' component={"p"} className={`${styles.sustainability_content}`}>
                        Simon India Limited envisions a future where sustainability is not just a commitment but a core driver of innovation. We believe that the integration of advanced technologies and sustainable methods will reshape the way we design, construct, and maintain projects, ultimately contributing to a greener, more resilient world.
                        <br/><br/>
                        Our holistic approach to sustainability is reflected in our continuous engagement with:
                    </Typography>
                </Grid>
                <Grid sx={{width:{xs:"100%", sm:"50%", textAlign:'center'}}}>
                    <img src="/assets/images/invision_img.png" alt="" style={{width: isMobile? "100%":""}} />
                </Grid>
            </Grid>
        </Grid>
        </>
    )
}

export default VisionSus;