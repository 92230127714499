
import React from 'react';
import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';
import CountUp from 'react-countup';
import styles from './services.module.css';
import { isMobile } from 'react-device-detect';


const ExperienceDetails = () =>{

    return (
        <Grid className={styles.service_container} 
            sx={{backgroundColor: "#ECF2F6", paddingTop: '100px', paddingBottom: "100px", paddingLeft: isMobile? "36px": "0px", paddingRight: isMobile? "36px": "0px"}} >
            <Grid className={styles.service_subcontainer} >
                <Grid className={styles.experience_card}>
                    <Grid sx={{width:{xs:"100%", sm:"25%"}, maxWidth: isMobile? "100%": 216}}>
                        <Typography variant='h3' component={"h3"} className={styles.experience_title}>
                            <CountUp delay={2} end={25} suffix="+" />
                        </Typography>
                        <div className={styles.experience_line}>&nbsp;</div>
                        <Typography variant='body2' component={"p"} className={styles.experience_desc}>Years of average experience among our engineering experts</Typography>
                    </Grid>
                    <Grid sx={{width:{xs:"100%", sm:"25%"}, maxWidth: isMobile? "100%": 216}}>
                        <Typography variant='h3' component={"h3"} className={styles.experience_title}>
                            <CountUp delay={2} end={50} suffix="+" />
                        </Typography>
                        <div className={styles.experience_line}>&nbsp;</div>
                        <Typography variant='body2' component={"p"} className={styles.experience_desc}>Empaneled subject matter experts</Typography>
                    </Grid>
                    <Grid sx={{width:{xs:"100%", sm:"25%"}, maxWidth: isMobile? "100%": 216}}>
                        <Typography variant='h3' component={"h3"} className={styles.experience_title}>
                        <CountUp delay={2} end={60} suffix="+" />
                        </Typography>
                        <div className={styles.experience_line}>&nbsp;</div>
                        <Typography variant='body2' component={"p"} className={styles.experience_desc}>Satisfied clients across the globe</Typography>
                    </Grid>
                    <Grid sx={{width:{xs:"100%", sm:"25%"}, maxWidth: isMobile? "100%": 216}}>
                        <Typography variant='h3' component={"h3"} className={styles.experience_title}>
                        <CountUp delay={2} end={75} suffix="+" />
                        </Typography>
                        <div className={styles.experience_line}>&nbsp;</div>
                        <Typography variant='body2' component={"p"} className={styles.experience_desc}>Major projects delivered in the last 27 years</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

}

export default ExperienceDetails;