import React from 'react';
import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';
import styles from './aboutus.module.css';
import Slider from 'react-slick';
import { isMobile } from 'react-device-detect';

const AboutStrength = () => {


    const settings = {
        dots: false,
        arrows: false,
        dotsClass: 'slick-dots',
        className: 'center',
        centerMode: false,
        infinite: false,
        centerPadding: '30px',
        slidesToShow: 4,
        slidesToScroll: Math.min(1),
        draggable: false,
        // touchMove: false,
        speed: 500,
        responsive: [
            { 
            breakpoint: 1025,
                setting: {

                }
            },
            {
                breakpoint: 450,
                settings: {
                    dots: true,
                    draggable: true,
                    arrows: false,
                    centerMode: false,
                    infinite: false,
                    slidesToShow: 1,
                }
            }
        ]
    }

    const StrengthData = [
        <>
        <Grid className={styles.strength_card_contain}>
            <Grid item  size={{xs:12, sm:3}} className={styles.strength_card}>
                <Grid sx={{paddingTop:'30px', paddingBottom:'1rem', borderBottom:'2px solid #CEE86B' }}>
                    <img src="/assets/images/management.png" alt="" />
                </Grid>
                <Typography variant="h3" component={"h3"} className={styles.strength_card_subtitle} sx={{marginTop:'1rem'}}>
                    Strong organizational structure
                </Typography>
                <Typography variant="body2" component={"p"} className={styles.strength_card_content} sx={{marginTop:'1rem'}}>
                Backed by the USD 3 billion Adventz Group, Simon India benefits from a robust organizational structure. This solid foundation enables us to deliver comprehensive and reliable EPC and EPCM services, ensuring project success through financial strength and strategic support.
                </Typography>
            </Grid> 
        </Grid>
        </>,
        <>
        <Grid className={styles.strength_card_contain}>
            <Grid item columns={{xs:12, sm:3}} className={styles.strength_card}>
                <Grid sx={{paddingTop:'30px', paddingBottom:'1rem', borderBottom:'2px solid #CEE86B' }}>
                    <img src="/assets/images/professional.png" alt="" />
                </Grid>
                <Typography variant="h3" component={"h3"} className={styles.strength_card_subtitle} sx={{marginTop:'1rem'}}>
                    Diverse engineering expertise
                </Typography>
                <Typography variant="body2" component={"p"} className={styles.strength_card_content} sx={{marginTop:'1rem'}}>
                    Our team comprises professionals with extensive engineering and project management experience across multiple industries and geographies. This diversity enhances our ability to tackle complex projects with innovative and optimized solutions tailored to each sector’s unique requirements.
                </Typography>
            </Grid>
        </Grid>
        </>,
        <>
        <Grid className={styles.strength_card_contain}>
            <Grid item columns={{xs:12, sm:3}} className={styles.strength_card}>
                <Grid sx={{paddingTop:'30px', paddingBottom:'1rem', borderBottom:'2px solid #CEE86B' }}>
                    <img src="/assets/images/policies.png" alt="" />
                </Grid>
                <Typography variant="h3" component={"h3"} className={styles.strength_card_subtitle} sx={{marginTop:'1rem'}}>
                    Robust quality assurance
                </Typography>
                <Typography variant="body2" component={"p"} className={styles.strength_card_content} sx={{marginTop:'1rem'}}>
                    We operate as a system-driven organization with robust quality policies and standard operating procedures. This approach ensures consistency, reliability, and excellence in every project we undertake, adhering to the highest industry standards.
                </Typography>
            </Grid>
        </Grid>
        </>,
        <>
        <Grid className={styles.strength_card_contain}>
            <Grid item columns={{xs:12, sm:3}} className={styles.strength_card}>
                <Grid sx={{paddingTop:'30px', paddingBottom:'1rem', borderBottom:'2px solid #CEE86B' }}>
                    <img src="/assets/images/design-team.png" alt="" />
                </Grid>
                <Typography variant="h3" component={"h3"} className={styles.strength_card_subtitle} sx={{marginTop:'1rem'}}>
                    Collaborative partnerships
                </Typography>
                <Typography variant="body2" component={"p"} className={styles.strength_card_content} sx={{marginTop:'1rem'}}>
                    We collaborate and engage with subject matter experts and engineering subcontractors, fostering partnerships that enhance our capabilities. This network of experts allows us to deliver comprehensive, high-quality solutions for even the most challenging projects.
                </Typography>
            </Grid>
        </Grid>
        </>
    ]


    return (
        <Grid className={styles.strength_container}>
            <Grid item className={styles.strength_sub_container}>
                <Typography variant="h2" component={"h2"} className={styles.about_subtitle}>
                        Our strengths
                </Typography>
                {!isMobile
                    ? 
                    <Grid columns={{xs:12}} className={styles.strength_card_container}>
                        {
                            StrengthData.map(item =>{
                                return item;
                            })
                        }
                    
                    </Grid>
                    :
                    <Grid columns={{xs:12}} className={styles.strength_card_container}>
                        <Slider {...settings}>
                            {StrengthData}
                        </Slider>
                    </Grid>
                    }
                
            </Grid>
        </Grid>
    )

}

export default AboutStrength;