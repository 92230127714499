import React from 'react';
import Grid from '@mui/material/Grid2';
import CountUp from 'react-countup';
import { Typography, } from '@mui/material';
import styles from './aboutus.module.css';
import { isMobile } from 'react-device-detect';


const AboutGroup = () => {

    return (
        <Grid className={styles.group_container}>
            <Grid className={styles.group_subcontainer}>
                <Typography variant="h3" component={"h3"} className={styles.group_title}>About Adventz</Typography>
                <Grid className={styles.group_card_container} sx={{width:{xs:"100%", sm:"90%"}}}>
                    <Grid columns={{xs:12, sm:3}} className={styles.group_card}>
                        <Typography variant="body2" component={"p"} className={styles.group_card_title}>
                            <CountUp delay={2} end={6000} decimal=' ' decimals={0} suffix="+" />
                        </Typography>
                        <Typography variant="body2" component={"p"} className={styles.group_card_content}>Employees</Typography>
                    </Grid>
                    <Grid columns={{xs:12, sm:3}} className={styles.group_card}>
                        <Typography variant="body2" component={"p"} className={styles.group_card_title}>3 Billion USD</Typography>
                        <Typography variant="body2" component={"p"} className={styles.group_card_content}>Global revenue</Typography>
                    </Grid>
                    <Grid columns={{xs:12, sm:3}} className={styles.group_card}>
                        <Typography variant="body2" component={"p"} className={styles.group_card_title}>
                            <CountUp delay={2} end={4} decimal=' ' suffix="+" />
                        </Typography>
                        <Typography variant="body2" component={"p"} className={styles.group_card_content}>Industrial verticals</Typography>
                    </Grid>
                    <Grid columns={{xs:12, sm:3}} className={styles.group_card}>
                        <Typography variant="body2" component={"p"} className={styles.group_card_title}>
                            <CountUp delay={2} end={75} decimal=' ' suffix="+" />
                        </Typography>
                        <Typography variant="body2" component={"p"} className={styles.group_card_content}>Years of trust & legacy</Typography>
                    </Grid>
                </Grid>
                <Grid className={styles.group_card_image}>
                    <Grid sx={{width:{xs:'100%', sm:"70%"}, paddingLeft: isMobile ? "36px" : "0px", paddingRight: isMobile ? "36px" : "0px"}}>
                        <Typography variant="body2" component={"p"} className={styles.group_card_content}>
                            Adventz is a USD 3 billion conglomerate led by Mr. Saroj Kumar Poddar, comprises 25+ businesses nationally and internationally.  Our presence spans diverse sectors, including agriculture, engineering, infrastructure, real estate, consumer durables, and financial services.  
                        <br/><br/>
                            Among India’s top conglomerates, Adventz is committed to delivering high-quality, sustainable solutions across various industries. Major companies under the Adventz umbrella include Zuari Industries Ltd., Texmaco Rail & Engineering Ltd., Simon India Ltd., and Zuari Agro Chemicals Ltd., each excelling in their respective fields and contributing to our collective success.</Typography>
                    </Grid>
                    <Grid className={styles.group_img} sx={{width:{xs:'100%', sm:"30%"}}}>
                        <img src="/assets/images/group_img.png" alt="/" />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

}

export default AboutGroup;