import React from 'react';
import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';

import styles from './expertise.module.css';
import { isMobile } from 'react-device-detect';


const DeliveryApproach = () => {
    return (
        <Grid className={styles.expertise_container} sx={{paddingTop:"20px", paddingBottom: "100px"}}>
            <Grid className={`${styles.expertise_subcontainer} ${styles.delivery_container}`}>
                <Typography variant="h3" component={"h3"} className={styles.expertise_title}
                    sx={{width:"100%", textAlign:'center', marginBottom: isMobile? "0px": "20px"}}>Simon India’s Project Delivery Approach</Typography>
                <img src="/assets/images/delivery_icon.png" alt="" style={{marginBottom:"20px"}} />
                <img src= {isMobile ? "/assets/images/delivery_img_mobile.png" : "/assets/images/delivery_img.png"} alt=""/> 
            </Grid>
        </Grid>
    )

}

export default DeliveryApproach;