import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import {Typography} from '@mui/material';
import PageBanner from '../../components/pageBanner';
import AdvantageC from '../../components/advantageC';
// import PageContent from '../../components/pageContent';

import styles from './terms.module.css';

const Disclaimer = () => {

    const bannerPath = [
        {title: 'Home', url: '/'},
        {title: "Disclaimer", url: ''}
    ]

    useEffect(()=>{
        window.scrollTo({top:0, left:0})
    },[])

    const details = "The information contained in this website is for general information purposes only. The information is provided by M/s Simon India Ltd. While we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you may place on such information is therefore strictly at your own risk. The application and impact of professional advice and services can vary widely based on the specific facts/laws involved. Given the changing nature of laws, rules and regulations, and the inherent hazards of electronic communication, there may be delays, omissions or inaccuracies in information contained in this site. ";

    return (
        <Grid sx={{width: "100%"}}>
            <PageBanner title="Disclaimer" bannerPath={bannerPath}
          />
          <Grid className={styles.page_container}>
            <Grid className={styles.page_subcontainer}>
                <Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"2rem"}}
                dangerouslySetInnerHTML={{__html:details}}
                />
                <Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"1rem"}}
                dangerouslySetInnerHTML={{
                    __html:`While we have made every attempt to ensure that the information contained in this site has been obtained from reliable sources, M/s Simon India Ltd is not responsible for any errors or omissions, or for the results obtained from the use of this information. All information in this site is provided "as is", with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability and fitness for a particular purpose. In no event will M/s Simon India Ltd, or employees thereof be liable to you or anyone else for any decision made or action taken in reliance on the information in this Site or for any consequential, special or similar damages in connection with, the use of this website.
                    <br/><br/>
                    Certain links in this site connect to other websites maintained by third parties over whom M/s Simon India Ltd. has no control. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
<br/><br/>
The Project images as shown are true depiction of our work either actually carried out at site or design model created by us. Please note that we make every effort to ensure our project images are as accurate as possible and align closely with the actual ones, however these images may differ from the actual work due to reproduction limitations and limitations of viewing images at different resolutions, hue, brightness, contrast, and other screen variations. Rest of the images other than project images displayed on this website are illustrative in nature and used for informational and visual purposes. We seek your accommodation and understanding in the event of any discrepancy and encourage you to reach out with any concerns or questions regarding exhibited images. M/s Simon India Ltd. makes no representations as to the accuracy or any other aspect of information contained in other websites.
<br/><br/>
Every effort is made to keep the website up and running smoothly. However, M/s Simon India Ltd. takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.
                    `
                }}
                />
                
            </Grid>
            <Grid className={styles.page_subcontainer} sx={{backgroundColor:"#ECF2F6", marginTop:0, marginBottom:"", padding:"4rem"}}>
            <Typography variant="body2" component={"p"} className={styles.page_content} sx={{width: "100%", fontSize: "1.25rem !important", fontWeight: 600, marginBottom:"1rem !important"}}
                dangerouslySetInnerHTML={{
                    __html:"Head - IT, Simon India Ltd."
                }}
                />
                <Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom: "0px !important"}}
                dangerouslySetInnerHTML={{
                    __html:"Global Business Park, Tower A, 8th Floor, Sector-26, MG Road, Gurgaon-122002, HARYANA, INDIA <br/>Phone: 0124-4518500"
                }}
                />
            </Grid>
            <AdvantageC />
        </Grid>
            
</Grid>
    )
}

export default Disclaimer;