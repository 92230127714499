// import 'https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap';
import React,{ Suspense } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';

// import Router from './Router';
import Header from './components/header';
import Footer from './components/footer';

import Home from './pages/Home'
import AboutUs from './pages/aboutus';
import ValuePreposition from './pages/valuePreposition';
import LeadershipTeam from './pages/leadershipTeam';
import Services from './pages/services';
import WhatsNew from './pages/whatsnew';
import Expertise from './pages/expertise';
import ProjectGallery from './pages/projectGallery';
import ProjectQuality from './pages/projectQuality';
import FaqPage from './pages/faq';
import SupplyPartner from './pages/joinus/supply';
import OurCulture from './pages/ourculture';
import ApplyJob from './pages/joinus/apply';
import Sustainbility from './pages/sustainbility';
import Disclaimer from './pages/disclaimer'
import Privacy from './pages/privacy'

// import PageLoader from "./components/pageLoader/PageLoader";

// const Home = React.lazy(() => "import ./pages/Home");
// const AboutUs = React.lazy(() => "import ./pages/aboutus");

const PageLoading = () =>{
  return <p> Page is loading </p>
}

const App =() => {

  const elementRoute = <Routes>
    <Route path={'/'} element={<Suspense fallback={<PageLoading />}><Home /></Suspense>} />
    <Route path={'/about'} element={<Suspense fallback={<PageLoading />}><AboutUs /></Suspense>} />
    <Route path={'/value-proposition'} element={<ValuePreposition />} />
    <Route path='/leadership' element={<LeadershipTeam />} />
    <Route path='/sustainability' element={<Sustainbility />} />
    <Route path='/services' element={<Services />} />
    <Route path='/news' element={<WhatsNew />} />
    <Route path="/expertise" element={<Expertise />} />
    <Route path="/project-gallery" element={<ProjectGallery />} />
    <Route path="/project-quality" element={<ProjectQuality />} />
    <Route path="/faq" element={<FaqPage />} />
    <Route path="/our-culture" element={<OurCulture />} />
    <Route path="/grow-together/:id" element={<SupplyPartner />} />
    <Route path="/apply" element={<ApplyJob />} />
    <Route path="/disclaimer" element={<Disclaimer />} />
    <Route path="/privacy" element={<Privacy />} />
    <Route path={'*'} element={<Home />} />
  </Routes>

  return (
    <div className="App">
      <Header />
        {elementRoute}
     <Footer />
    </div>
  );
}

export default App;
