import React from 'react';
import Grid from '@mui/material/Grid2';

import styles from './valuePreposition.module.css'
import { Typography } from '@mui/material';


const ValuePrepo = (props) => {

    return (
        <Grid className={styles.value_container} sx={{paddingBottom: '120px'}}>
            <Grid className={`${styles.value_subcontainer} ${styles.value_prepos}`}>
                <Grid className={styles.values_card_container} sx={{ width:{xs:"100%", sm:"50%"}}}>
                    <Grid className={styles.valuepre_card}>
                        <div style={{borderLeft: "1px solid #FFF", padding:'1rem', marginBottom: "1rem"}}>
                            <Typography variant='h3' component={"h3"} className={`${styles.main_title} ${styles.colorWhite}`} sx={{marginBottom:'1rem'}}>Our value<br/>proposition</Typography>
                        </div>
                        <Typography className={`${styles.sub_content} ${styles.colorWhite}`}>
                            Our value proposition centers on delivering exceptional results by leveraging advanced engineering, strategic problem-solving, and efficient project execution. With a commitment to quality and adaptability, we navigate complex challenges to meet your unique needs. Supported by a wealth of global experience, Simon India provides reliable, high-impact solutions that drive sustainable growth and long-term success.
                        </Typography>
                    </Grid>
                    <Grid className={styles.value_card}>
                        <Typography variant='h3' component={"h3"} className={`${styles.grow_card_title} ${styles.colorBlue}`} sx={{marginBottom:'1rem'}}>Global Experience</Typography>
                        <Typography variant='body2' component={"p"} className={`${styles.sub_content}`}>Our extensive global experience ensures seamless project execution across diverse markets, adhering to international standards and adapting to varied cultural and industry landscapes.</Typography>
                    </Grid>
                </Grid>
                <Grid className={styles.values_card_container} sx={{width:{xs:"100%", sm:"50%"}}}>
                    <Grid className={styles.value_card}>
                        <Typography variant='h3' component={"h3"} className={`${styles.grow_card_title} ${styles.colorBlue}`} sx={{marginBottom:'1rem'}}>Critical Thinking Capability</Typography>
                        <Typography variant='body2' component={"p"} className={`${styles.sub_content}`}>Our team specializes in complex problem-solving, root cause analysis, and strategic project optimization, ensuring innovative solutions and effective risk management for challenging engineering projects.</Typography>
                    </Grid>
                    <Grid className={styles.value_card}>
                        <Typography variant='h3' component={"h3"} className={`${styles.grow_card_title} ${styles.colorBlue}`} sx={{marginBottom:'1rem'}}>High-end Engineering</Typography>
                        <Typography variant='body2' component={"p"} className={`${styles.sub_content}`}>Simon India excels in delivering state-of-the-art engineering solutions, integrating advanced technologies and innovative designs to meet the most demanding project requirements with precision and excellence.</Typography>
                    </Grid>
                    <Grid className={styles.value_card}>
                        <Typography variant='h3' component={"h3"} className={`${styles.grow_card_title} ${styles.colorBlue}`} sx={{marginBottom:'1rem'}}>Speed to Delivery</Typography>
                        <Typography variant='body2' component={"p"} className={`${styles.sub_content}`}>We prioritize efficient project execution through agile methodologies, ensuring timely delivery without compromising quality, helping clients stay ahead in a competitive market.</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>   
    )

}

export default ValuePrepo;