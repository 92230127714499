import React from 'react';
// import Grid from '@mui/material/Grid2';
import Container from '@mui/material/Container';
// import Typography from '@mui/material/Typography';

import styles from './banner.module.css';
import { isMobile } from 'react-device-detect';


const HomeBanner = () => {

    return (
        <Container className={styles.banner_section} >
            <video className='.landing' loop autoPlay muted={true} style={{width:'100%', heigth:"auto"}}>
                <source 
                    src= {!isMobile ? '/assets/images/simon_india_banner_video.mp4' : "/assets/images/simon_india_banner_video_mobile.mp4"}
                    type='video/mp4' />
            </video>
            {/* <script> $( window ).load(function() { document.getElementsByClassName('.landing').get(0).play()}); </script> */}
            {/* <Grid item className={styles.banner_image} >
                <img src="/assets/images/banner-box.png" alt= "banner box" />
            </Grid>
            <Grid item className={styles.heading_container}>
                <Typography className={styles.banner_text} variant='p' component={'h1'}>Delivering Inspired</Typography>
                <Typography className={styles.banner_text} variant='p' component={'h1'}>Engineering Excellence</Typography>
            </Grid>
             */}
            {/* <Grid item className={styles.text_container}>
                <Typography variant='p' component={'p'}>A Global Engineering Company</Typography>    
                <img src='/assets/images/banner-building.png' alt="" />
            </Grid> */}
        </Container>
        
    )


}

export default HomeBanner;