import React from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import {Typography, Card, CardContent, CardMedia, CardActions, IconButton} from '@mui/material'; 
import Slider from 'react-slick';
import { isMobile } from 'react-device-detect';


import { ArrowForward } from '@mui/icons-material';

import { } from '../aboutus/aboutus.css';

import styles from '../aboutus/aboutus.module.css';


const ProjectExplore = () => {
    const navigate = useNavigate();

    const settings= {
        dots: false,
        arrows: false,
        dotsClass: 'slick-dots',
        className: 'center',
        centerMode: false,
        infinite: false,
        centerPadding: '20px',
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: false,
        
        // nextArrow: <NextArrow />,
        // prevArrow: <PrevArrow />,
        speed: 500,
        responsive: [
           { 
            breakpoint: 1025,
            settings: {

                }
            },
            { 
            breakpoint: 450,
            settings: {
                    dots: true,
                    centerMode: true,
                    // infinite: true,
                    slidesToShow: 1,
                    swipeToSlide: true,
                }
            }
        ]
    }

    const growCardData = [
        <>
            <Card sx={{ maxWidth: 232, width:'100%', borderBottom: "10px solid #CEE86B"  }}>
                <CardMedia
                    sx={{ height: 144 }}
                    image="/assets/images/prjexplore_img1.png"
                    title="contact"
                />
                <CardContent>
                    <Typography variant="body2" component={"p"} className={styles.grow_card_title}>Know our
                    <br /> expertise</Typography>
                </CardContent>
                <CardActions>
                    <IconButton size="small" onClick={()=> navigate("/expertise")} ><ArrowForward className={styles.grow_button}/></IconButton>
                </CardActions>
            </Card>
        </>,
        <>
            <Card sx={{ maxWidth: 232,  width:'100%', borderBottom: "10px solid #CEE86B"  }}>
                <CardMedia
                    sx={{ height: 144 }}
                    image="/assets/images/prjexplore_img2.png"
                    title="contact"
                />
                <CardContent>
                    <Typography variant="body2" component={"p"} className={styles.grow_card_title}>Understand our <br/>culture</Typography>
                </CardContent>
                <CardActions>
                    <IconButton size="small" onClick={()=> navigate("/our-culture")}><ArrowForward className={styles.grow_button}/></IconButton>
                </CardActions>
            </Card>
        </>,
        <>
            <Card sx={{ maxWidth: 232,  width:'100%', borderBottom: "10px solid #CEE86B"  }}>
                <CardMedia
                    sx={{ height: 144 }}
                    image="/assets/images/prjexplore_img3.png"
                    title="contact"
                />
                <CardContent>
                    <Typography variant="body2" component={"p"} className={styles.grow_card_title}>Our value <br/>proposition</Typography>
                </CardContent>
                <CardActions>
                    <IconButton size="small" onClick={()=> navigate("/value-proposition")}><ArrowForward className={styles.grow_button}/></IconButton>
                </CardActions>
            </Card> 
        </>
    ]

    return (
        <Grid className={styles.aboutexplore_container}>
            <Grid className={styles.aboutexplore_subcontainer}>
                <Typography variant='h2' component={"h2"} className={`${styles.about_subtitle} ${styles.colorWhite}`} >Explore</Typography>
                {!isMobile 
                ? <Grid id="projectExplpore" className={`${styles.grow_card} grows_card`}>
                    <Slider  {...settings} >
                        {growCardData}
                    </Slider>    
                </Grid>
                : <Grid sx={{marginTop: "2rem", display:"flex", flexFlow:"column", rowGap: "1rem"}}>
                    <Card sx={{display:"flex", width:'100%', borderRight: "10px solid #CEE86B"  }}>
                        <CardMedia
                            sx={{ height: 114, width:103 }}
                            image="/assets/images/prjexplore_img1.png"
                            title="contact"
                        />
                        <CardContent sx={{padding:"0.6rem 1.5rem !important"}}>
                            <Typography variant="body2" component={"p"} className={styles.grow_card_title}>Know our <br/>expertise</Typography>
                            <IconButton size="small" onClick={()=> navigate("/expertise")}><ArrowForward className={styles.grow_button}/></IconButton>
                        </CardContent>
                    </Card>
                    <Card sx={{display:"flex", width:'100%', borderRight: "10px solid #CEE86B"  }}>
                        <CardMedia
                            sx={{ height: 114, width:103 }}
                            image="/assets/images/prjexplore_img2.png"
                            title="contact"
                        />
                        <CardContent sx={{padding:"0.6rem 1.5rem !important"}}>
                            <Typography variant="body2" component={"p"} className={styles.grow_card_title}>Understand our<br/>culture</Typography>
                            <IconButton size="small" onClick={()=> navigate("/our-culture")}><ArrowForward className={styles.grow_button}/></IconButton>
                        </CardContent>
                    </Card>
                    <Card sx={{display:"flex", width:'100%', borderRight: "10px solid #CEE86B"  }}>
                        <CardMedia
                            sx={{ height: 114, width:103 }}
                            image="/assets/images/prjexplore_img3.png"
                            title="contact"
                        />
                        <CardContent sx={{padding:"0.6rem 1.5rem !important"}}>
                            <Typography variant="body2" component={"p"} className={styles.grow_card_title}>Our value <br/>proposition</Typography>
                            <IconButton size="small" onClick={()=> navigate("/value-preposition")}><ArrowForward className={styles.grow_button}/></IconButton>
                        </CardContent>
                    </Card>
                </Grid>
                }
                
            </Grid>
        </Grid>
    )
}

export default ProjectExplore;