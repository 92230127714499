import React, { useRef } from 'react';
import Grid from '@mui/material/Grid2';
// import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import "react-tabs-scrollable/dist/rts.css";

import styles from './aboutus.module.css';
import { } from './aboutus.css';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { East, West } from '@mui/icons-material';
import { isMobile } from 'react-device-detect';
import { Tabs, Tab } from "react-tabs-scrollable";


const AboutStory = () => {

    const [value, setValue] = React.useState(0);
    const tabRef = useRef(null);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const TabScreen = ({ activeTab, idx, ...props }) => {
      return (
        <div
          className="animate__animated animate__fadeInLeft"
          role="tabpanel"
          {...props}
        >
          {activeTab === idx && <div className="mx-4">{props.children}</div>}
        </div>
      );
    };

    return (
        <Grid className={styles.storycontainer}>
            <Grid className={styles.storysubcontainer}>
                <ArrowDropUpIcon className={styles.story_icon} />
                <Typography variant="h2" component={"h2"} className={styles.story_title}>Our Story</Typography>
                <Typography variant="body2" component={"p"} className={styles.story_content}>
                    Simon India has grown from a joint venture to a leading engineering powerhouse, expanding globally and executing major projects for industry giants such as Adani, SABIC, and GSFC. Our portfolio includes cutting-edge sulfuric acid plants and large-scale ammonia projects, all delivered with precision and innovation. Partner with us to bring your vision to life through sustainable, top-tier engineering solutions.
                </Typography>
                <Grid sx={{marginTop:'2rem'}}>
                  <Tabs
                  activeTab={value}
                  action={tabRef}
                  onTabClick={handleChange}
                  tabsScrollAmount={ !isMobile ? 3 : 1}
                  rightBtnIcon={<East color="#2182FF" fontSize='24px' />}
                  leftBtnIcon={<West color="#2182FF" fontSize='24px' />}
                  hideNavBtns={false}
                  hideNavBtnsOnMobile={false}
                  showTabsScroll={false}
                  >
                    <Tab key={'1995'} > 1995</Tab>
                    <Tab key={'2003'} > 2003</Tab>
                    <Tab key={'2006'} > 2006</Tab>
                    <Tab key={'2008'} > 2008</Tab>
                    <Tab key={'2012'} > 2012</Tab>
                    <Tab key={'2016'} > 2016</Tab>
                    <Tab key={'2017'} > 2017</Tab>
                    <Tab key={'2018'} > 2018</Tab>
                    <Tab key={'2019'} > 2019</Tab>
                    <Tab key={'2023'} > 2023</Tab>
                    <Tab key={'2024'} > 2024</Tab>
                    
                  </Tabs>
                
                    <Grid className={styles.tabs_container}>
                      <TabScreen activeTab={value} idx={0} key={"1995"}>
                        <Grid className={styles.story_card}>
                              <img src="/assets/images/story_1995.jpg" alt="" />
                              <Typography variant='body2' component={"p"} className={styles.group_card_content}>
                                In 1995, Simon India Limited began its journey as a joint venture between Simon Carves and Zuari Industries. This partnership laid the foundation for what would become a leading player in engineering, procurement, and construction, marking the start of a vision to deliver world-class solutions to industries across the globe.
                              </Typography>
                          </Grid>
                      </TabScreen>
                      <TabScreen activeTab={value} idx={1} key={"2003"}>
                        <Grid className={styles.story_card}>
                              <img src="/assets/images/story_2003.jpg" alt="" />
                              <Typography variant='body2' component={"p"} className={styles.group_card_content}>
                                In 2003, Simon India became a 100% subsidiary of Zuari Industries Limited (ZIL). This transition strengthened our foundation and provided the resources and support needed to expand our capabilities, allowing us to focus on innovation and excellence across all our projects.
                              </Typography>
                          </Grid>
                      </TabScreen>
                      <TabScreen activeTab={value} idx={2} key={"2006"}>
                        <Grid className={styles.story_card}>
                              <img src="/assets/images/story_2006.jpg" alt="" />
                              <Typography variant='body2' component={"p"} className={styles.group_card_content}>
                                In 2006, Simon India set its sights on international markets, establishing a branch office in Saudi Arabia. This move opened doors to significant opportunities in the Middle East, showcasing our ability to deliver complex engineering solutions on a global stage and solidifying our reputation as a trusted partner in the region.
                              </Typography>
                        </Grid>
                      </TabScreen>
                      <TabScreen activeTab={value} idx={3} key={"2008"}>
                          <Grid className={styles.story_card}>
                              <img src="/assets/images/story_2008.jpg" alt="" />
                              <Typography variant='body2' component={"p"} className={styles.group_card_content}>
                                Simon India took on one of its most prestigious international projects in 2008, the EPC Ammonia Abatement Project for SABIC in Saudi Arabia. This achievement demonstrated our ability to execute large-scale industrial projects with precision and excellence, expanding our footprint in the global market.
                              </Typography>
                          </Grid>
                      </TabScreen>
                      <TabScreen activeTab={value} idx={4} key={"2012"}>
                          <Grid className={styles.story_card}>
                              <img src="/assets/images/story_2012.jpg" alt="" />
                              <Typography variant='body2' component={"p"} className={styles.group_card_content}>
                                In 2012, we successfully completed a 2000 TPD Sulphuric Acid EPC Project for Paradeep Phosphates Limited (PPL). This complex project further reinforced our expertise in the chemical industry, showcasing our ability to deliver customized, innovative engineering solutions for critical infrastructure.
                              </Typography>
                          </Grid>
                      </TabScreen>
                      <TabScreen activeTab={value} idx={5} key={"2016"}>
                        <Grid className={styles.story_card}>
                              <img src="/assets/images/story_2016.jpg" alt="" />
                              <Typography variant='body2' component={"p"} className={styles.group_card_content}>
                                Simon India continued to grow in 2016 with the successful completion of the 50,000 MT Caprolactum Project for GSFC. This milestone highlighted our unwavering commitment to delivering high-quality solutions, pushing the boundaries of what’s possible in engineering and construction.
                              </Typography>
                          </Grid>
                      </TabScreen>
                      <TabScreen activeTab={value} idx={6} key={"2017"}>
                        <Grid className={styles.story_card}>
                              <img src="/assets/images/story_2017.jpg" alt="" />
                              <Typography variant='body2' component={"p"} className={styles.group_card_content}>
                              In 2017, Simon India undertook a significant project with the EPC development of the Mundra LPG Terminal for Adani. This project highlighted our capability to execute large-scale infrastructure projects, delivering top-tier engineering solutions with precision and efficiency.
                              </Typography>
                          </Grid>
                      </TabScreen>
                      <TabScreen activeTab={value} idx={7} key={"2018"}>
                          <Grid className={styles.story_card}>
                              <img src="/assets/images/story_2018.jpg" alt="" />
                              <Typography variant='body2' component={"p"} className={styles.group_card_content}>
                                Building on our momentum, 2018 saw us successfully execute an EPC project for Saudi Kayan. This project further established Simon India's reputation for excellence in the Middle East, showcasing our ability to collaborate with global partners to deliver high-impact, timely solutions.
                              </Typography>
                          </Grid>
                      </TabScreen>
                      <TabScreen activeTab={value} idx={8} key={"2019"}>
                          <Grid className={styles.story_card}>
                              <img src="/assets/images/story_2019.jpg" alt="" />
                              <Typography variant='body2' component={"p"} className={styles.group_card_content}>
                                In 2019, we added another achievement to our portfolio with the delivery of a 10,000 MT PA Tank System at GSFC, Sikka. Our technical expertise and dedication to excellence once again came to the forefront, as we completed this large-scale project with precision and efficiency.
                              </Typography>
                          </Grid>
                      </TabScreen>
                      <TabScreen activeTab={value} idx={9} key={"2023"}>
                          <Grid className={styles.story_card}>
                              <img src="/assets/images/story_2023.jpg" alt="" />
                              <Typography variant='body2' component={"p"} className={styles.group_card_content}>
                              As we enter 2023, Simon India has already embarked on major international projects, including the Chlor Alkali Project for Al Ghanem in Iraq and the Cl2 Handling Project for KLJ in Qatar. These projects highlight our continued expansion and growing influence in the global market.
                              </Typography>
                          </Grid>
                      </TabScreen>
                      <TabScreen activeTab={value} idx={10} key={"2024"}>
                          <Grid className={styles.story_card}>
                              <img src="/assets/images/story_2024.jpg" alt="" />
                              <Typography variant='body2' component={"p"} className={styles.group_card_content}>
                              Looking ahead to 2024, Simon India will complete the Alkali Scrubber EPC Project and provide engineering services for OSBL SAP. Our unwavering focus on innovation, sustainability, and excellence will continue to drive us forward, as we set new benchmarks in engineering, procurement, and construction.
                              </Typography>
                          </Grid>
                      </TabScreen>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>        
    )


}

export default AboutStory;