import React from 'react';
import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';

import styles from './projectQuality.module.css';
import { isMobile } from 'react-device-detect';


const SafetyQuality = () => {

    return (

        <Grid className={styles.safety_container} 
            sx={{paddingTop: !isMobile ? "120px": "40px", paddingBottom:"120px"}}>
            <Grid className={styles.safety_subcontainer}>
                <Typography variant="h3" component={"h3"} className={styles.safety_title}>Quality</Typography>
                <Typography variant="body2" component={"p"} className={styles.safety_content} sx={{marginTop: "1rem"}}>
                    At Simon India, quality assurance is integrated into every phase of our projects. From initial design engineering to final execution, we adhere to international quality standards to ensure excellence in every deliverable. Our team is committed to continuous improvement through regular quality audits, precision in engineering, and client-focused outcomes. This dedication to quality has built our reputation for delivering reliable, high-performance solutions that exceed expectations.
                </Typography>
                <Grid sx={{display: "flex", width:"100%", marginTop:"4rem", justifyContent: "center" }}>
                    <img src= { !isMobile ? '/assets/images/quality_img.png' : "/assets/images/quality_img_mobile.png" } alt="" />
                </Grid>
            </Grid>
        </Grid>
    )


}

export default SafetyQuality;