import React from 'react';
import Grid from '@mui/material/Grid2';
import styles from './aboutus.module.css';
import { isMobile } from 'react-device-detect';


const AboutMission = () => {

    return (
        <Grid className={styles.mission_container}>
            <Grid className={styles.mission_subcontainer}>
                
                <Grid className={styles.mission_card_image} sx={{width:{xs:"100%", marginTop: "0px !important"}}}>
                    <img src= {isMobile? "/assets/images/about_vision_mobile.jpg": "/assets/images/about_vision.jpg"} alt="vision" />
                </Grid>
                <Grid className={styles.mission_card_image} sx={{justifyContent: "flex-end"}}>
                    <img src= { isMobile ? "/assets/images/about_mission_mobile.jpg" : "/assets/images/about_mission.jpg"} alt="mission" />
                </Grid>
            </Grid>
        </Grid>
    )

}

export default AboutMission;