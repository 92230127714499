import React, {useEffect} from 'react';
import Grid from '@mui/material/Grid2';
import PageBanner from '../../components/pageBanner';
import AdvantageC from '../../components/advantageC';
import PageContent from '../../components/pageContent';
import News from './news';
import NewsArchieve from './newsArchieve';
import Awards from './awards';

const WhatsNew = () => {

    const bannerPath = [
        {title: 'Home', url: '/'},
        {title: "What's New", url: ''}
    ]
    useEffect(()=>{
        window.scrollTo({top:0, left:0})
    },[])


    return (
        <Grid sx={{width: "100%"}}>
            <PageBanner title="What's New" bannerimage="whatsnew_banner.png" 
                bannerPath={bannerPath}  />
            <PageContent 
                details="Are you curious to know what’s new at Simon India? Welcome to this comprehensive collection of press releases, media articles, and coverage from both online and offline sources. It is your go-to source for all the latest updates and insights about our company. Explore how Simon India is making headlines with groundbreaking projects, industry innovations, and our contributions to the global engineering landscape. 
<br/><br/>
Stay tuned to this space to keep up with our journey and industry impact.
" />
            <News />
            <NewsArchieve />
            <Awards />
            <AdvantageC />
</Grid>
    )
}

export default WhatsNew;