import React from 'react';
import Grid from '@mui/material/Grid2';
// import { Typography, Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import AddIcon from '@mui/icons-material/Add';
// import RemoveIcon from '@mui/icons-material/Remove';
import { isMobile } from 'react-device-detect';

import { } from './faqpage.css';
import styles from './faqpage.module.css';

const FaqContainer = (props) =>{
    const {marginTop} = props;
   

    // const CustomExpandIcon = () => {
    //     return (
    //       <Box
    //         sx={{
    //           ".Mui-expanded & > .collapsIconWrapper": {
    //             display: "none"
    //           },
    //           ".expandIconWrapper": {
    //             display: "none"
    //           },
    //           ".Mui-expanded & > .expandIconWrapper": {
    //             display: "block"
    //           }
    //         }}
    //       >
    //         <RemoveIcon className={`${styles.accord_icon} expandIconWrapper`} />
    //         <AddIcon className={`${styles.accord_icon} collapsIconWrapper`} />
    //       </Box>
    //     );
    //   };

    return (
        <Grid className={styles.faqpage_container} 
          sx={{paddingLeft: isMobile ? "18px" : 0, paddingRight: isMobile ? "18px" : 0}}>
            <Grid className={styles.faqpage_subcontainer} sx={{marginTop: marginTop, marginBottom: !isMobile ? '120px' : "80px"}}>
                {/* <Typography variant="h3" component={"h3"} className={styles.sub_title} sx={{paddingLeft: isMobile ? "18px" : 0, width:'100%', textAlign: isMobile ?'left':'center', marginBottom: "30px"}}>Frequently Asked Questions</Typography> */}
                <Grid>
                    {props.content}
                </Grid>
            </Grid>

        </Grid>
    )


}

export default FaqContainer;