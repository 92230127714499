import React, {useEffect} from 'react';
import Grid from '@mui/material/Grid2';
import PageBanner from '../../components/pageBanner';
import AdvantageC from '../../components/advantageC';
import PageContent from '../../components/pageContent';
import ExploreProject from '../../components/exploreProject';
import ProjectClient from './projectClient';
import ServiceProvide from './serviceProvide';
import ExperienceDetails from './experienceDetails';


const Services = () => {

    const bannerPath = [
        {title: 'Home', url: '/'},
        {title: 'Services', url: ''}
    ]
    useEffect(()=>{
        window.scrollTo({top:0, left:0})
    },[])


    return (
        <Grid sx={{width: "100%"}}>

            <PageBanner title="Services" bannerimage="services_banner.png" 
                bannerPath={bannerPath}  />
            <PageContent 
            details="At Simon India, we take pride in delivering a holistic range of services that meet the intricate demands of today's engineering projects. Our expertise spans multiple sectors, enabling us to provide customized solutions that tackle industry specific challenges. Whether initiating a new project or enhancing an existing one, our services are structured to support each phase of the project lifecycle.
<br/><br/>
By integrating cutting-edge technology, robust methodologies, and deep industry insights, we ensure that your projects are not only successful but also sustainable in the long run. Our team of dedicated experts brings a wealth of experience and a deep commitment to excellence.
<br/><br/>
Choose Simon India as your partner for a seamless, efficient, and results-oriented approach to all your engineering and construction needs.
" />
            <ServiceProvide />
            <ProjectClient 
                quotation="For the past nine years at Simon India Limited, the 'working together, winning together' mindset has been key to our project successes and my own professional growth. With structured processes that foster collaboration, I've been able to continuously develop my skills and achieve meaningful career milestones. It's been a privilege to be part of Simon’s growth journey."
                client="Mohammad Waseem" 
                designation="Piping Engineer, Simon India Limited."/>
            <ExperienceDetails />
            <ExploreProject />

            <AdvantageC />
        </Grid>
    )

}

export default Services;