import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';

import { } from './whatsnew.css'
import styles from './whatsnew.module.css';
import { Typography, Link, Modal, Button } from '@mui/material';
import { isMobile } from 'react-device-detect';
// import { Tabs, Tab } from "react-tabs-scrollable";
// import { East, West } from '@mui/icons-material';

const NewsArchieve = () => {

  const [modalopen, setModalopen] = useState(false);
  const [modalImg, setModalImg] = useState(null);
  const handleModal = () =>{
    setModalopen(false);
    setModalImg(null);
  }

    // const [value, setValue] = useState(0);
    // const tabRef = useRef(null);

    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    // }


    // const TabScreen = ({ activeTab, idx, ...props }) => {
    //     return (
    //       <div
    //         className="animate__animated animate__fadeInLeft"
    //         role="tabpanel"
    //         {...props}
    //       >
    //         {activeTab === idx && <div className="mx-4">{props.children}</div>}
    //       </div>
    //     );
    //   };


    return (
        <Grid className={styles.whatsnew_container} sx={{ backgroundColor: "#DAF0FF", paddingTop: "80px", paddingBottom: "80px"}}>
            <Grid className={styles.whatsnew_subcontainer}>
                <Typography variant='h3' component={"h3"} className={styles.whatsnew_title}>
                    News Archives
                </Typography>
                <Grid sx={{marginTop:'2rem'}}>
                  {/* <Tabs
                  activeTab={value}
                  action={tabRef}
                  onTabClick={handleChange}
                  tabsScrollAmount={3}
                  rightBtnIcon={<East color="#2182FF" fontSize='24px' />}
                  leftBtnIcon={<West color="#2182FF" fontSize='24px' />}
                  hideNavBtns={false}
                  hideNavBtnsOnMobile={false}
                  showTabsScroll={false}
                  >
                    <Tab key={'2024'} > 2024</Tab>
                    <Tab key={'2023'} > 2023</Tab>
                    <Tab key={'2019'} > 2022</Tab>
                    <Tab key={'2018'} > 2021</Tab>
                    <Tab key={'2017'} > 2020</Tab>
                    <Tab key={'2016'} > 2019</Tab>
                    <Tab key={'2015'} > 2018</Tab>
                  </Tabs> */}
                
                    <Grid sx={{marginTop:'2rem'}}>
                      {/* <TabScreen activeTab={value} idx={0} key={"2024"}> */}
                        <Grid className={styles.tabpanel_container}>
                            <Typography variant='body2' component={"p"} className={styles.news_content_title}>
                              Simon India bags ₹370-Cr contract from Adani Ports
                            </Typography>
                            <Grid className={styles.tabpanel_grid}>
                              <Grid sx={{width: {sm:"40%", xs:"100%", display: 'flex', justifyContent: 'flex-start'}}}>
                                  <Typography variant='body2' component={"p"} className={styles.whatsnew_content} sx={{fontSize: !isMobile ? "": "0.8rem !important"}}>
                                      {"June 06, 2017  |  Financial Express"}
                                  </Typography>
                              </Grid>
                              <Grid sx={{width: {sm:"60%", xs:"30%"}, textAlign:"right"}}><Button sx={{padding:0, minWidth:0}} onClick={()=>{setModalImg(1); setModalopen(true)}}><img src='/assets/images/news_button.png' alt="" /></Button></Grid>
                            </Grid>
                        </Grid>
                        <Grid className={styles.tabpanel_container}>
                            <Typography variant='body2' component={"p"} className={styles.news_content_title}>
                              Industry - Simon ends quality improvement at GFSC
                            </Typography>
                            <Grid className={styles.tabpanel_grid}>
                              <Grid sx={{width: {sm:"40%", xs:"100%", display: 'flex', justifyContent: 'flex-start'}}}>
                                  <Typography variant='body2' component={"p"} className={styles.whatsnew_content} sx={{fontSize: !isMobile ? "": "0.8rem !important"}}>
                                      {"March 26, 2018  |  Indian Express"}
                                  </Typography>
                              </Grid>
                              <Grid sx={{width: {sm:"60%", xs:"30%"}, textAlign:"right"}}>
                              <Button sx={{padding:0, minWidth:0}} onClick={()=>{setModalImg(2); setModalopen(true)}}><img src='/assets/images/news_button.png' alt="" /></Button>
                              </Grid>
                            </Grid>
                        </Grid>
                        <Grid className={styles.tabpanel_container}>
                            <Typography variant='body2' component={"p"} className={styles.news_content_title}>
                              Simon India Limited bags ₹370Cr Mundra contract
                            </Typography>
                            <Grid className={styles.tabpanel_grid}>
                              <Grid sx={{width: {sm:"40%", xs:"100%", display: 'flex', justifyContent: 'flex-start'}}}>
                                  <Typography variant='body2' component={"p"} className={styles.whatsnew_content} sx={{fontSize: !isMobile ? "": "0.8rem !important"}}>
                                      {"June 06, 2017  |  Financial Chronicle National"}
                                  </Typography>
                                  {/* <Typography variant='body2' component={"p"} className={styles.whatsnew_content}>
                                      Financial Chronicle National
                                  </Typography> */}
                              </Grid>
                              <Grid sx={{width: {sm:"60%", xs:"30%"}, textAlign:"right"}}>
                              <Button sx={{padding:0, minWidth:0}} onClick={()=>{setModalImg(3); setModalopen(true)}}><img src='/assets/images/news_button.png' alt="" /></Button></Grid>
                            </Grid>
                        </Grid>
                        
                      {/* </TabScreen> */}
                      {/* <TabScreen activeTab={value} idx={1} key={"2023"}>
                      <Grid className={styles.tabpanel_container}>
                              <Typography variant='body2' component={"p"} className={styles.news_content_title}>
                                Industry - Simon ends quality improvement at GFSC
                              </Typography>
                              <Grid className={styles.tabpanel_grid}>
                                <Grid sx={{width: {sm:"40%", xs:"100%", display: 'flex', justifyContent: 'space-between'}}}>
                                    <Typography variant='body2' component={"p"} className={styles.whatsnew_content}>
                                      March 26, 2018
                                    </Typography>
                                    <Typography variant='body2' component={"p"} className={styles.whatsnew_content}>
                                        Indian Express
                                    </Typography>
                                </Grid>
                                <Grid sx={{width: {sm:"60%", xs:"100%"}, textAlign:"right"}}><Link href="/#"><img src='/assets/images/news_button.png' alt="" /></Link></Grid>
                              </Grid>
                          </Grid>
                      </TabScreen>
                      <TabScreen activeTab={value} idx={2} key={"2022"}>
                      <Grid className={styles.tabpanel_container}>
                              <Typography variant='body2' component={"p"} className={styles.news_content_title}>
                                Simon India Limited bags ₹370Cr Mundra contract
                              </Typography>
                              <Grid className={styles.tabpanel_grid}>
                                <Grid sx={{width: {sm:"40%", xs:"100%", display: 'flex', justifyContent: 'space-between'}}}>
                                    <Typography variant='body2' component={"p"} className={styles.whatsnew_content}>
                                        June 06, 2017
                                    </Typography>
                                    <Typography variant='body2' component={"p"} className={styles.whatsnew_content}>
                                    Financial Chronicle National
                                    </Typography>
                                </Grid>
                                <Grid sx={{width: {sm:"60%", xs:"100%"}, textAlign:"right"}}><Link href="/#"><img src='/assets/images/news_button.png' alt="" /></Link></Grid>
                              </Grid>
                          </Grid>
                      </TabScreen>
                      <TabScreen activeTab={value} idx={3} key={"2021"}>
                      <Grid sx={{display: 'flex', gap:'30px', width:"80%"}}></Grid>
                      </TabScreen>
                      <TabScreen activeTab={value} idx={4} key={"2020"}>
                      <Grid sx={{display: 'flex', gap:'30px', width:"80%"}}></Grid>
                      </TabScreen>
                      <TabScreen activeTab={value} idx={5} key={"2019"}>
                      <Grid sx={{display: 'flex', gap:'30px', width:"80%"}}></Grid>
                      </TabScreen>
                      <TabScreen activeTab={value} idx={6} key={"2018"}>
                      <Grid sx={{display: 'flex', gap:'30px', width:"80%"}}></Grid>
                      </TabScreen> */}
                    </Grid>
                </Grid>
            </Grid>
            <Modal
              open={modalopen}
              onClose={handleModal}
              aria-labelledby="modal-modal-image"
              aria-describedby="modal-modal-description"
            >
              <div className={styles.modalContainer}>
              <img src={`/assets/images/archieve${modalImg}.jpg`} alt="" />
              </div>
             </Modal>
        </Grid>
    )


}

export default NewsArchieve;