import React from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import {Typography, Card, CardContent, CardMedia, CardActions, IconButton} from '@mui/material'; 

import { ArrowForward } from '@mui/icons-material';
// import Slider from 'react-slick';

import { } from './ourculture.css'
import styles from './ourculture.module.css';
import { isMobile } from 'react-device-detect';


const CultureExplore = () => {

    const navigate = useNavigate();

    // const settings= {
    //     dots: false,
    //     arrows: false,
    //     dotsClass: 'slick-dots',
    //     className: 'center',
    //     centerMode: false,
    //     infinite: false,
    //     centerPadding: '20px',
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     swipeToSlide: false,
    //     // nextArrow: <NextArrow />,
    //     // prevArrow: <PrevArrow />,
    //     speed: 500,
    //     responsive: [
    //         { 
    //         breakpoint: 1025,
    //         settings: {

    //             }
    //         },
    //         { 
    //         breakpoint: 450,
    //         settings: {
    //                 dots: true,
    //                 centerMode: true,
    //                 // infinite: true,
    //                 slidesToShow: 1,
    //                 swipeToSlide: true,
    //             }
    //         }
    //     ]
    // }

    // const exploreData = [
    //     <>
    //     <Card sx={{ maxWidth: 232, width:'100%', borderBottom: "10px solid #CEE86B"  }}>
    //         <CardMedia
    //             sx={{ height: 144 }}
    //             image="/assets/images/explore_img1.png"
    //             title="contact"
    //         />
    //         <CardContent>
    //             <Typography variant="body2" component={"p"} className={styles.grow_card_title}>Know our
    //             <br /> expertise</Typography>
    //         </CardContent>
    //         <CardActions>
    //             <IconButton size="small" onClick={()=> navigate("/expertise")}><ArrowForward className={styles.grow_button}/></IconButton>
    //         </CardActions>
    //     </Card>
    //     </>,
    //     <>
    //     <Card sx={{ maxWidth: 232,  width:'100%', borderBottom: "10px solid #CEE86B"  }}>
    //         <CardMedia
    //             sx={{ height: 144 }}
    //             image="/assets/images/explore_img2.png"
    //             title="contact"
    //         />
    //         <CardContent>
    //             <Typography variant="body2" component={"p"} className={styles.grow_card_title}>Understand our <br/>culture</Typography>
    //         </CardContent>
    //         <CardActions>
    //             <IconButton size="small" onClick={()=> navigate("/our-culture")}><ArrowForward className={styles.grow_button}/></IconButton>
    //         </CardActions>
    //     </Card>
    //     </>,
    //     <>
    //     <Card sx={{ maxWidth: 232,  width:'100%', borderBottom: "10px solid #CEE86B"  }}>
    //         <CardMedia
    //             sx={{ height: 144 }}
    //             image="/assets/images/explore_img3.png"
    //             title="contact"
    //         />
    //         <CardContent>
    //             <Typography variant="body2" component={"p"} className={styles.grow_card_title}>Meet our<br/>leaders</Typography>
    //         </CardContent>
    //         <CardActions>
    //             <IconButton size="small" onClick={()=> navigate("/leadership")}><ArrowForward className={styles.grow_button}/></IconButton>
    //         </CardActions>
    //     </Card>
    //     </>
    // ]

    return (
        <Grid className={`${styles.culture_container} ${styles.explore_container}`}>
            <Grid className={styles.culture_subcontainer}>
                <Typography variant='h3' component={"h3"} className={`${styles.culture_sub_sub_title} ${styles.colorWhite}`} >Explore</Typography>
                <Grid className={styles.grow_card}>
                    <Card sx={{ maxWidth: 232,  width:'100%', borderBottom: "10px solid #CEE86B"  }}>
                        <CardMedia
                            sx={{ height: 144 }}
                            image="/assets/images/explore_img2_.png"
                            title="contact"
                        />
                        <CardContent>
                            <Typography variant="body2" component={"p"} className={styles.grow_card_title}>Understand <br/>Simon India</Typography>
                        </CardContent>
                        <CardActions>
                            <IconButton size="small" onClick={()=> navigate("/about")}><ArrowForward className={styles.grow_button}/></IconButton>
                        </CardActions>
                    </Card>
                    <Card sx={{ maxWidth: 232, width:'100%', borderBottom: "10px solid #CEE86B"  }}>
                        <CardMedia
                            sx={{ height: 144 }}
                            image="/assets/images/explore_img4.png"
                            title="contact"
                        />
                        <CardContent>
                            <Typography variant="body2" component={"p"} className={styles.grow_card_title}>Meet our<br />leaders</Typography>
                        </CardContent>
                        <CardActions>
                            <IconButton size="small" onClick={()=> navigate("/leadership")}><ArrowForward className={styles.grow_button}/></IconButton>
                        </CardActions>
                    </Card>
                    <Card sx={{ maxWidth: 232,  width:'100%', borderBottom: "10px solid #CEE86B"  }}>
                        <CardMedia
                            sx={{ height: 144 }}
                            image="/assets/images/explore_img3.png"
                            title="contact"
                        />
                        <CardContent>
                            <Typography variant="body2" component={"p"} className={styles.grow_card_title}>Our value<br/>proposition </Typography>
                        </CardContent>
                        <CardActions>
                            <IconButton size="small" onClick={()=> navigate("/value-proposition")}><ArrowForward className={styles.grow_button}/></IconButton>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid className={`${styles.grow_cards} grow_cardss`}>
                    <Card sx={{ display: "flex",  width:'100%', borderBottom: !isMobile? "10px solid #CEE86B" : "unset", borderRight: !isMobile? "10px solid #CEE86B" : "unset"  }}>
                        <CardMedia
                            sx={{ height: 118, width:104 }}
                            image="/assets/images/explore_img2_.png"
                            title="contact"
                        />
                        <CardContent sx={{padding: "0.7rem 1.5rem !important"}}>
                            <Typography variant="body2" component={"p"} className={styles.grow_card_title}>Understand<br/>Simon India</Typography>
                            <IconButton size="small" onClick={()=> navigate("/about")}><ArrowForward className={styles.grow_button}/></IconButton>
                        </CardContent>
                    </Card>
                    <Card sx={{ display: "flex",  width:'100%', borderBottom: !isMobile? "10px solid #CEE86B" : "unset", borderRight: !isMobile? "10px solid #CEE86B" : "unset"  }}>
                        <CardMedia
                            sx={{ height: 118, width:104 }}
                            image="/assets/images/explore_img4.png"
                            title="contact"
                        />
                        <CardContent sx={{padding: "0.7rem 1.5rem !important"}}>
                            <Typography variant="body2" component={"p"} className={styles.grow_card_title}>Meet our<br/>leaders</Typography>
                            <IconButton size="small" onClick={()=> navigate("/leadership")}><ArrowForward className={styles.grow_button}/></IconButton>
                        </CardContent>
                        
                    </Card>
                    <Card sx={{ display: "flex",  width:'100%', borderBottom: !isMobile? "10px solid #CEE86B" : "unset", borderRight: !isMobile? "10px solid #CEE86B" : "unset"  }}>
                        <CardMedia
                            sx={{ height: 118, width:104 }}
                            image="/assets/images/explore_img3.png"
                            title="contact"
                        />
                        <CardContent sx={{padding: "0.7rem 1.5rem !important"}}>
                            <Typography variant="body2" component={"p"} className={styles.grow_card_title}>Our value<br/>proposition</Typography>
                            <IconButton size="small" onClick={()=> navigate("/value-proposition")}><ArrowForward className={styles.grow_button}/></IconButton>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CultureExplore;