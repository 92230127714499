import React from 'react';
import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';


import styles from './sustainbility.module.css'
import { isMobile } from 'react-device-detect';


const FocusSus = () => {

    return (
        <>
        <Grid className={styles.sustainability_container} sx={{paddingTop:"60px"}}>
            <Grid className={styles.sustainability_subcontainer} sx={{marginBottom:"2rem"}} >
                <Typography variant="h2" component={"h2"} className={`${styles.sustainability_title}`}
                 sx={{marginBottm: "2rem", paddingLeft: isMobile ? "18px": "0px", paddingRight: isMobile ? "18px": "0px"}}>
                        Our Focus on Sustainable Construction
                </Typography>
            </Grid>
            
        </Grid>
        <Grid className={styles.sustainability_container} sx={{flexFlow:"column", paddingLeft: "0px !important", paddingRight: "0px !important"}}>
            <Grid className= {styles.focus_container}>
                <Grid sx={{width: isMobile ? "100%" : "90%"}}>
                    <img src="/assets/images/sus_focus_img.jpg" alt="" style={{width: isMobile? "100%": ""}}/>
                </Grid>
                <Grid sx={{paddingLeft: isMobile? "18px":"0px",paddingRight: isMobile? "18px":"0px", width:{xs:"100%", sm:"50%"}, display:'flex', flexFlow:'column', rowGap:'2rem'}}>
                    <Grid className={styles.focus_card}>
                        <img src="/assets/images/recycle_icon.png" alt="" />
                        <Typography variant='body2' component={"p"} className={`${styles.focus_content} ${styles.colorBlue}`}>
                            Using <br />
                            recycled materials
                        </Typography>
                    </Grid>
                    <Grid className={styles.focus_card}>
                        <img src="/assets/images/waste_icon.png" alt="" />
                        <Typography variant='body2' component={"p"} className={`${styles.focus_content} ${styles.colorBlue}`}>
                            Reducing <br />
                            waste
                        </Typography>
                    </Grid>
                    <Grid className={styles.focus_card}>
                        <img src="/assets/images/water_icon.png" alt="" />
                        <Typography variant='body2' component={"p"} className={`${styles.focus_content} ${styles.colorBlue}`}
                        >
                            Minimizing <br />
                            water consumption
                        </Typography>
                    </Grid>
                    
                </Grid>
            </Grid>
        </Grid>
        <Grid className={styles.sustainability_container} sx={{paddingBottom:"160px"}}>
            <Grid className={styles.sustainability_subcontainer} sx={{marginTop:"2rem"}} >
                <Typography variant='body2' component={"p"} className={styles.sustainability_content}>
                    Our efforts extend beyond energy and resource efficiency; we are committed to sustainable construction practices. By utilizing durable and eco-friendly materials, reducing waste, and minimizing water consumption, we set a new standard for sustainability in the industry. Our goal is to build infrastructure that not only benefits our clients but also serves as a model for responsible construction, ensuring a healthier planet for future generations.
                    <br/><br/>
                    Our approach integrates advanced construction techniques that reduce environmental impact while maintaining high-quality outputs. We also engage in continuous research to implement best practices, ensuring that every project contributes positively to the ecosystem.

                </Typography>
            </Grid>

        </Grid>
        </>
    )
}

export default FocusSus;