import React from 'react';
import Grid from '@mui/material/Grid2'

import PageBanner from '../../../components/pageBanner';
import PageContent from '../../../components/pageContent';
import ApplyForm from './applyForm';
import AdvantageC from '../../../components/advantageC';

const ApplyJob = () => {

    const bannerPath = [
        {title: 'Home', url: '/'},
        {title: 'Join us', url: '/'},
        {title: 'Apply', url: ''}
    ]

    return (
        <Grid sx={{display: 'flex', flexFlow:'column', width: '100%'}}>
            <PageBanner title= "Apply" bannerimage="apply_banner.jpg" bannerPath={bannerPath} />
            <PageContent details="We seek individuals who are passionate about life, eager to learn, and have a strong understanding of the engineering field.
<br />
If you're interested in joining our growth journey, please provide your basic details below, and a team member will reach out to set up a conversation"  />
            <ApplyForm />
            <AdvantageC />
        </Grid>
    )
}

export default ApplyJob;