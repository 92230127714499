import React from 'react';
import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';

import styles from './services.module.css'
import { isMobile } from 'react-device-detect';


const ProjectClient = (props) => {
    const {quotation, client, designation} = props;
    return (
        <Grid className={styles.service_container} 
            sx={{paddingTop:"80px", paddingBottom: "100px", paddingLeft: isMobile? "36px": "0px", paddingRight: isMobile? "36px": "0px"}}>
            <Grid className={styles.service_subcontainer}>
                <img src="/assets/images/rightarrow.png" alt="" style={{width: isMobile? "21%" :"5%"}} />
                <Grid sx={{width: isMobile? "100%" :"90%"}}>
                    <Typography variant='h3' component={"h3"} className={styles.customer_title}>
                        {quotation}
                    </Typography>
                    <Typography variant='body2' component={"p"} className={styles.customer_desig}>
                        {client}
                    </Typography>
                    <Typography variant='body2' component={"p"} className={styles.customer_location}>
                        {designation}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ProjectClient;