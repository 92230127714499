import React, {useEffect} from 'react';
import Grid from '@mui/material/Grid2';
import {Typography} from '@mui/material';
import PageBanner from '../../components/pageBanner';
import AdvantageC from '../../components/advantageC';
// import PageContent from '../../components/pageContent';

import styles from './privacy.module.css';

const Privacy = () => {

    const bannerPath = [
        {title: 'Home', url: '/'},
        {title: "Privacy Policy", url: ''}
    ]

    useEffect(()=>{
            window.scrollTo({top:0, left:0})
    },[])

    const details = `Simon India Limited having its Registered Office at Birla Mill Complex, P.O. Birla Lines, G.T. Road, near Clock Tower, Delhi-110007 and Corporate Office at 8th Floor, TOWER-A, GLOBAL BUSINESS PARK, Mehrauli-Gurgaon Rd, Sikanderpur, Sector 26, Gurugram, Haryana 122002 and (“SIL”, “Company”, “we”, “us” or “our”) is managing and operating the website _ simonindia.com  (“website”) for the users of the website (“User” or “Users” or “you” or “your”).  This Privacy Policy together with terms & conditions explains how the website collects, uses, discloses, processes, deals, handles, shares, transfers and safeguards any personal information you provide when using this website. At Simon India, we are committed to protecting your privacy.
<br/><br/>
When you visit this site and provide your information in order to use our service, you agree to be bound by the terms and conditions of this Privacy Policy. We shall not use the User’s information in any manner except as provided under this Privacy Policy. Please do not access the site if you disagree with this.
<br/><br/>
This document is an electronic record and is governed by the provisions under the Information Technology Act, 2000 and rules made thereunder, as may be applicable, and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.`;

    return (
        <Grid sx={{width: "100%"}}>
            <PageBanner title="Privacy Policy" bannerPath={bannerPath}
          />
          <Grid className={styles.page_container}>
            <Grid className={styles.page_subcontainer}>
                <Typography variant="h3" component={"h1"} className={styles.page_heading}>Privacy Policy</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{__html:details}}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop:"4rem"}}>Definitions</Typography>
                <ul>
                    <li><Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"0.8rem !important"}}
                dangerouslySetInnerHTML={{
                    __html:`
                    <span style="font-weight:600">Cookies:</span> a small file placed on your device by our website or mobile application when you either visit or use certain features of our website or mobile application. A cookie generally allows a website to remember your actions or preference for a certain period of time.`
                }}
                /></li>
                <li><Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"0.8rem !important"}}
                dangerouslySetInnerHTML={{
                    __html:`
                    <span style="font-weight:600">Data:</span> includes non-personal information, personal information and sensitive personal information about you, which either directly or indirectly in combination with other information, could allow you to be identified when you visit our stores, website and/or mobile application.`
                }}
                /></li>
                <li><Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"0.8rem !important"}}
                dangerouslySetInnerHTML={{
                    __html:`
                    <span style="font-weight:600">Data Protection Laws:</span> The Information Technology Act, 2000, Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011, Digital Personal Data Protection Act, 2023 and/ or any applicable law(s), regulation(s) or rule(s) relating to the processing of Data for the time being in force or as amended from time to time.`
                }}
                /></li>
                <li><Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"0.8rem !important"}}
                dangerouslySetInnerHTML={{
                    __html:`
                    <span style="font-weight:600">Partners:</span> select third parties with whom we have contracts for the businesses including third party carriers and their agents`
                }}
                /></li>
                <li><Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"0.8rem !important"}}
                dangerouslySetInnerHTML={{
                    __html:`
                    Service Providers includes entities to whom we shall disclose your Data in order to process information for a specific purpose.`
                }}
                /></li>
                <li><Typography variant="body2" component={"p"} className={styles.page_content} 
                dangerouslySetInnerHTML={{
                    __html:`
                    <span style="font-weight:600">Simon India Limited:</span> a company incorporated in India whose registered office is at Birla Mill Complex, P.O. Birla Lines, G.T. Road, near Clock Tower, Delhi-110007`
                }}
                /></li>
                </ul>
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop:"4rem"}}>Information Collection & Use</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"0.8rem !important"}}
                dangerouslySetInnerHTML={{
                    __html:`We may collect information from you when you register on our website. This information may include your name, email address, phone/ mobile number. We may automatically receive your computer's internet protocol (IP) address when you browse the website, which gives us information about your operating system and browser.
<br/><br/>
We may collect, use or process your information including Personal Information and Sensitive Personal Data or Information for the following purposes:`
                }}
                />
                <ul>
                    <li>
                    <Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"5px !important"}}
                        dangerouslySetInnerHTML={{
                            __html:`For creating and giving you access to your registered account on the website.`
                        }}
                    />
                    </li>
                    <li>
                    <Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"5px !important"}}
                        dangerouslySetInnerHTML={{
                            __html:`To inform you about our services, offers, updates, upcoming events, including providing you information in relation to technical notices, security alerts.`
                        }}
                    />
                    </li>
                    <li>
                    <Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"5px !important"}}
                        dangerouslySetInnerHTML={{
                            __html:`For internal analytical and research purposes such as auditing, data analysis and research to improve our services and customer communications.`
                        }}
                    />
                    </li>
                    <li>
                    <Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"5px !important"}}
                        dangerouslySetInnerHTML={{
                            __html:`To meet any legal or regulatory requirement or comply with a request from any governmental or judicial authority.`
                        }}
                    />
                    </li>
                    <li>
                    <Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"5px !important"}}
                        dangerouslySetInnerHTML={{
                            __html:`To resolve any request, dispute, grievance or complaint raised by you in relation to your use of the website.`
                        }}
                    />
                    </li>
                    <li>
                    <Typography variant="body2" component={"p"} className={styles.page_content}
                        dangerouslySetInnerHTML={{
                            __html:`To detect or monitor any fraudulent or illegal activity on the website.`
                        }}
                    />
                    </li>
                </ul>


                <Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop:"4rem"}}>Disclosure of Information</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:`We may also disclose your information when such disclosure is requisitioned under any law or when we, in our sole discretion, deem it necessary in order to protect our rights or the rights of other Users, to prevent harm to persons or property, to fight fraud and credit risk.
<br/><br/>
We may also disclose or transfer your information to any third party as a part of reorganization or a sale of the assets, division or transfer of a part or whole of us. We shall ensure that third party to which we transfer or sell our assets will have appropriate confidentiality and security measures, at least as protective as those described in this Privacy Policy, to handle your Personal Information. You will have the opportunity to opt out of any such transfer if the new entity's planned processing of your information differs materially from that set forth in this Privacy Policy.
<br/><br/>
To further understand how these providers will treat your personal information, we advise you to read the respective privacy policies of the third-party service providers.
<br/><br/>
While we take best efforts to ensure that your information including Personal Information, Financial Information and Sensitive Personal Information or Data is duly protected by undertaking security measures prescribed under applicable laws, you are strongly advised to exercise reasonable discretion while providing Personal Information or Financial Information while using the services given that the Internet is susceptible to security breaches for which Simon India shall not be liable.`
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop:"4rem"}}>Security</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:`In order to protect your personal information, we closely monitor all appropriate physical, electronic and procedural measures. We take reasonable efforts to prevent your information from unauthorized access, disclosure, or alteration.
<br/><br/>
Your information is contained within secured networks and is only accessible by a limited number of authorized persons who have access rights to such systems or otherwise require such information for the purposes provided in this Privacy Policy. These authorized persons are also under an obligation to keep such information confidential.
<br/><br/>
Users must accept the inherent security risks associated with data transfer via the internet and the World Wide Web whenever they utilize a website. Online sites can't always be completely safe, thus there are always additional hazards involved. Users are responsible to make sure that the login and password information for their accounts is secure. We request users to change their passwords as per the general guidance of banks and FIs. You agree not to hold us responsible for any breach of security or for any action of any third parties that receive your Personal Information or events that are beyond our reasonable control including but not limited to, acts of government, computer hacking, unauthorized access to computer data and storage device, computer crashes, breach of security and encryption.`
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop:"4rem"}}>Cookies</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:`Cookies are small text files that are stored on your device by the website. They contain information about the user's preferences and actions on the website.
<br/><br/>
We use cookies to enhance your browsing experience by remembering your preferences and settings and to analyse overall user trends and to help us improve our services.
<br/><br/>
Cookies will improve website performance by storing information about your previous visits which will help in faster loading and improve your overall user experience. It can also provide valuable insights into user behaviour by tracking which pages you visit and how long you spend on each page, helping us identify areas for improvement and make data-driven decisions.
<br/><br/>
By using our website, you consent to our use of cookies in accordance with our privacy policy. You have the option to manage and delete cookies from your browser settings. However, disabling cookies may limit the functionality of our website and it may impair, degrade or restrict access to certain areas of our website.`
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop:"4rem"}}>Third-Party Links</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:"Our website may contain links to third-party websites. We shall not be held responsible for the privacy policies or practices of the other third-party websites. We encourage you to read the privacy policy of any such website you visit."
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop:"4rem"}}>Consent </Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:`Once you visit the site or provide your information, you consent to the collection, use, storage, disclosure, further sharing and processing of your information as stated in this privacy policy.
                    <br/><br/>
                    You can withdraw your consent at any time by writing an email to us. Please take note that any withdrawal of permission will be subject to the rules of this Privacy Policy, any relevant T&C and applicable laws. However, please note that if you withdraw your consent, we may no longer be able to provide you with the corresponding service for which you have withdrawn your consent. It is hereby clarified that your decision to withdraw your consent will not affect the processing of Personal Information based on your previous consent prior to the withdrawal. 
                    `
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop:"4rem"}}>Rights</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:`We take great care to maintain the accuracy and timeliness of your personal information. You always can immediately access, modify and update your personal information using the site's capabilities.`
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop:"4rem"}}>Changes to this Policy</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:`We reserve the right to change this Privacy Policy from time to time. Please make sure to check the policy periodically to ensure that you are satisfied with the changes. Updated version will be effective as soon as it is accessible. Any changes will be immediately posted on our website and you are deemed to have accepted the terms of the updated Privacy Notice on your first use of our website or mobile application or first purchase of the products and/or services following the alterations. We will post any changes to this policy on our website and notify you if changes are significant. `
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop:"4rem"}}>Contact Us/ Grievance Redressal</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:`To request to review, update, or delete your personal information or to otherwise reach us, please submit a request by e-mailing us at cs.sil@adventz.com. You may contact us for any information on any services, service providers, partners and entities, questions or concerns regarding the usage of information as stated in this policy as per applicable law(s), regulation(s) and rules(s). We will respond to your request within 30 days.`
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop:"4rem"}}>Confidential Information Notice</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:`Simon India Limited strongly advises against submitting or posting any information that you consider confidential or proprietary on this website. If you choose to share such information, Simon India Limited will regard it as non-confidential. By submitting or posting materials, you grant Simon India Limited an unrestricted, irrevocable license to utilize, reproduce, display, modify, transmit, and distribute that information. Additionally, you agree that Simon India Limited is free to employ any ideas, concepts, know-how, or techniques you provide for any purpose on this platform.`
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop:"4rem"}}>Privacy Commitment</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:`We value your privacy and are committed to protecting it. This notice outlines our online information practices and your choices regarding the collection and use of your information, as permitted or mandated by applicable law. To ensure easy access, this notice is available on our homepage and at all points where personal information may be requested.
<br/><br/>
We are dedicated to using your personal information solely in ways that align with this Privacy Policy. We do not share, trade, sell, or rent any individual personal information collected through this site; however, we may gather and share aggregated data with third parties.`
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop:"4rem"}}>Links to Other Websites</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:`This website may contain links to third-party websites that are not controlled or affiliated with Simon India Limited. We do not make any claims regarding the privacy practices or terms of use of these external sites, nor can we ensure the accuracy, integrity, or quality of the information and materials available there. The inclusion of any link does not imply endorsement by Simon India Limited of the linked site or its provider.`
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop:"4rem"}}>Limitation of Liability</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:"Simon India Limited will not be liable to users of this site for:"
                }}
                />
                <ul>
                <li><Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"0.8rem !important"}}
                dangerouslySetInnerHTML={{
                    __html:`Any loss, injury, claim, liability, or damages of any kind arising from the use or inability to use the materials on this site, even if Simon India Limited was advised of the possibility of such damages, regardless of the legal theory (contract, tort, etc.);`
                }}
                /></li>
                <li><Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"0.8rem !important"}}
                dangerouslySetInnerHTML={{
                    __html:`Special, direct, indirect, incidental, punitive, exemplary, or consequential damages of any nature, including but not limited to attorney's fees, arising in connection with the use or inability to use this website or its content;`
                }}
                /></li>
                <li><Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"0.8rem !important"}}
                dangerouslySetInnerHTML={{
                    __html:`Claims resulting from errors, omissions, inaccuracies, or destructive features of any information presented on this site.`
                }}
                /></li>
                </ul>


                <Typography variant="h3" component={"h1"} className={styles.page_heading} sx={{marginTop: "6rem"}}>Terms & Conditions</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:"The following Terms and Conditions, when read in conjunction with the Privacy Policy, serve as a binding contract between you and Simon India Limited (“the Company”). You are presumed to have accepted all the following Terms and Conditions by visiting, viewing, or accessing any of the services or information produced, collected, collated, or provided by us through this website simonindia.com. Please carefully read these Terms before using this website since you agree to be bound by them. Please refrain from using this website if you do not agree to accept these terms and conditions."
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop: "4rem"}}>Disclaimer</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:"This website may contain forward-looking statements regarding the future business prospects and profitability of Simon India Limited. These statements are subject to various risks and uncertainties, and actual results may significantly differ from those projected. Factors that could impact the company's operations include downturns in the infrastructure sector, substantial changes in India’s political and economic landscape, tax regulations, labour relations, and litigation. Simon India Limited does not commit to announcing when these forward-looking statements become materially inaccurate or to updating any such statements made periodically by or on behalf of the company."
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop: "4rem"}}>Retention of Data</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:`The Company retains Data for as long as necessary for the use of our services or to provide access to and use of our website, or for other essential purposes such as complying with our legal obligations, resolving disputes, enforcing our agreements and as long as processing and retaining your Data is necessary for our legitimate interests. Because these needs can vary for different data types and purposes, actual retention periods can vary significantly.
<br/><br/>
Even if we delete your Data, including on account of exercise of your right, it may persist on backup or archival media for audit, legal, tax or regulatory purposes.
`
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop: "4rem"}}>Terms and Conditions of Use</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:`The information provided on this site is presented in good faith and intended for general informational purposes. Users should not rely on it for specific decisions, and no representations or warranties are made regarding its accuracy or completeness. The information on this website does not constitute an invitation to invest in Simon India Limited, its affiliates, or its group.
<br/><br/>
Users are granted a limited license to use the content of this website for personal, non-commercial purposes. No other rights, titles, or interests are granted to users by implication or otherwise. Simon India Limited is not liable for any loss, damage, or expense incurred by users as a result of using this website.
<br/><br/>
This site may contain links to external websites, which are provided solely for user convenience. Simon India Limited does not endorse or represent these third parties and is not liable for any damages, losses, or expenses resulting from the use of or access to these linked contents.
Simon India Limited reserves the right to change, revise, modify, add, or remove content from this website at any time without prior notice.
<br/><br/>
We request the user to carry out complete due diligence about the services and the related information before placing its reliance on the website content.
`
                }}
                />

                <Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop: "4rem"}}>User Conduct</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:`You agree to use the website only for lawful purposes and in a manner that does not infringe the rights of or restrict or inhibit the use and enjoyment of the website by any third party. You agree not to use the website for any illegal or unauthorized purposes, including but not limited to, unauthorized access to, use, or modification of data, unauthorized commercial advertising or solicitations and unauthorized downloading or distribution of copyrighted material. Any breach of the said conduct shall entail initiation of legal proceedings by Simon India Limited and compensation may be sought for the related damages.`
                }}
                />

                <Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop: "4rem"}}>Privacy Policy</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:`We will use your personal information in accordance with our commitment to privacy. Please review our Privacy Policy, which also governs your visit to this website, for additional information about our policies regarding the collection, use and disclosure of your personal information.`
                }}
                />

                <Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop: "4rem"}}>Indemnity</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:`You agree to indemnify, defend and hold harmless the website and its affiliates, directors, officers, employees and agents from and against any and all claims, damages, losses, liabilities and expenses, arising out of or in connection with your use of this website, or any breach of these terms and conditions. Indemnification provisions encompass, but are not limited to, any claims made by third parties related to your use of this website, the content you provide, as well as any violations of applicable laws or regulations.`
                }}
                />

                <Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop: "4rem"}}>Intellectual Property </Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:`This website and its content, including but not limited to, text, graphics, logos, images and software, are the property of Simon India Limited or its licensors and are protected by applicable intellectual property laws. You may not use any content or materials from the website for commercial purposes without our prior written consent.
                    <br/><br/>
                We respect the intellectual property rights of others, and we expect our users to do the same. Any content or materials posted on our platform must comply with applicable laws and regulations and must not infringe on the intellectual property rights of others. Any breach or incident of the said infringement shall entail initiation of legal proceedings by Simon India Limited and compensation may be sought for the related damages.`
                }}
                />

<Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop: "4rem"}}>Disclaimer of Warranty</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:`This website and its content are provided "as is" and "as available" without warranties of any kind, either express or implied, including but not limited to, warranties of merchantability, fitness for a particular purpose and non-infringement.`
                }}
                />

                <Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop: "4rem"}}>Limitation of Liability</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:"In no event shall the Company be liable for any damages, including but not limited to, direct, indirect, special, incidental, or consequential damages, arising out of the use of or inability to use the website, or the performance of the products or services offered through the website."
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop:"4rem"}}>Governing Law and Jurisdiction</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:"These terms and conditions are to be interpreted in accordance with Indian laws. In the case of any dispute or claim relating to these terms and conditions, the dispute or claim shall be subject to the exclusive jurisdiction of the courts at Delhi, India and no other courts shall have any jurisdiction in the matter."
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop:"4rem"}}>Changes to Terms & Conditions</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content}
                dangerouslySetInnerHTML={{
                    __html:"We reserve the right to modify these terms & conditions at any time without notice. Your continued use of the website after any changes to these terms & conditions constitutes your acceptance of the modified terms & conditions."
                }}
                />
                <Typography variant="h3" component={"h3"} className={styles.page_sub_content} sx={{marginTop:"4rem"}}>Severability</Typography>
                <Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom:"0px !important"}}
                dangerouslySetInnerHTML={{
                    __html:"Whenever possible, each section of this Privacy Policy shall be interpreted in a manner so as to be valid under applicable law. However, in the event any provision is held to be prohibited, or invalid, such provision shall be ineffective only to the extent of such prohibition or invalidity, without invalidating the remainder of such provision or other remaining provisions of this Privacy Policy."
                }}
                />
            </Grid>
            <Grid className={styles.page_subcontainer} sx={{backgroundColor:"#ECF2F6", marginTop:0, marginBottom:"", padding:"4rem"}}>
            <Typography variant="body2" component={"p"} className={styles.page_content} sx={{width: "100%", fontSize: "1.25rem !important", fontWeight: 600, marginBottom:"1rem !important"}}
                dangerouslySetInnerHTML={{
                    __html:"Head - IT, Simon India Ltd."
                }}
                />
                <Typography variant="body2" component={"p"} className={styles.page_content} sx={{marginBottom: "0px !important"}}
                dangerouslySetInnerHTML={{
                    __html:"Global Business Park, Tower A, 8th Floor, Sector-26, MG Road, Gurgaon-122002, HARYANA, INDIA <br/>Phone: 0124-4518500"
                }}
                />
            </Grid>
        <AdvantageC />
        </Grid>
            
</Grid>
    )
}

export default Privacy;